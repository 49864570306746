.EditorPanel{
  width: 100%;
  height: 100%;
  
}

.EditorPanel textarea{
  width: 100%;
  height: 100%;

  background-color: #1e1e1e;
  color: #d4d4d4;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: "liga" 0, "calt" 0;
  line-height: 18px;
  letter-spacing: 0px;
}

.monaco-editor {
  position: absolute;
}

.MonacoEditorPanel {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}