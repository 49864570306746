

.RepoEditor {
  border: 1px solid #999999;
  border-radius: 3px;
  margin: 4px;
}




.PlanEditor {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;

  header {
    display: flex;
  }
}