.MenuBar {
  display:flex;
  .ToolBar {
    flex-grow: 2;
  }
  h1 {
    font-size: 100%;
    margin-right: 8px;
    
  }
}

.ToolBarMenu {
  position: absolute;
  z-index: 1000;
  background-color: #282c34;
  border: 1px solid #444444;
}