.OutputPanel{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  textarea{
    width: 100%;
    height: 100%;

    background-color: #1e1e1e;
    color: #d4d4d4;
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-weight: normal;
    font-size: 12px;
    font-feature-settings: "liga" 0, "calt" 0;
    line-height: 18px;
    letter-spacing: 0px;
  }

  .showError {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 5fr 1fr;
  }

  .showError > *:first-child {
    border: 1px solid red;
  }

  //.preContainer {
    // overflow: scroll;
    // position: absolute;
    // min-height: 0;
    // max-width: 100%;
    //   max-height: 100%;
    //   overflow-x: scroll;
    //   overflow-y: scroll;
    pre {
      // max-width: 100%;
      // max-height: 100%;
      // overflow-x: scroll;
      // overflow-y: scroll;
      min-width: 0;
      min-height: 0;
      max-width: 100%;
      max-height: 100%;
      overflow: scroll;
    }
  //}
}

.output-iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.monaco-editor {
  position: absolute;
}

.ShadowDom {
  width: 100%;
  height: 100%;
}