.ListDocuments {
  .localDocuments {
    display: grid;
    grid-template-columns: 200px auto;
  }
}

.ListDocsForPlan {
  border: 1px solid #444444;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;

  display: flex;
  align-items: baseline;

  h2, button {
    margin-right: 8px;
  }
}