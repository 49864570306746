
.ResiazableQuads, .ResiazableQuadsSlots, .ResiazableSlots {
  .quad {
    border: 1px solid #444444;
    min-height: 0;
    min-width: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: scroll;
    display: inline-block;
    
  }

  .vbar {
    cursor:col-resize
  }

  .hbar {
    cursor:row-resize
  }

  .cbar {
    cursor:move;
  }

  .bar.selected {
    background-color: #007acc;
  }
}


.ResiazableQuads {
  background-color: #1e1e1e;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  color: #cccccc;

  position: relative;
  z-index: 0;

  display: grid;
  overflow: auto;
}


.ResiazableQuadsSlots {
  background-color: #1e1e1e;
  height: 100%;
  width: 100%;
  color: #cccccc;

  display: grid;
}

.ResiazableSlots {
  display: grid;
  max-height: 100%;
  height: 100%;
  width: 100%;
}
