
.Quadrant {
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;


  .top {
    display: flex;

    .tabs {
      flex-grow: 1;
    }

    .utility {
      flex-grow: 0;
    }
  }
}

.Quadrant > * {
  min-width: 0;
  min-height: 0;
  overflow: scroll;
}

.Quadrant button.selected {
  background-color: cornflowerblue;
}
